import MaxWidth from 'components/functional/maxWidth'
import Layout from 'components/global/layout'
import PageBody from 'components/shared/pageBody'
import PageHeader from 'components/shared/pageHeader'
import { graphql, Link } from 'gatsby'
import React from 'react'

const AboutTheatre = ({ data }) => {
  const mob = data.mobHeaderImg
  const tab = data.tabletHeaderImg
  const desk = data.deskHeaderImg
  const meta = {
    title: 'About The Theatre | The Mousetrap',
  }
  return (
    <Layout title={meta.title}>
      <PageHeader mobileHeader={mob} tabletHeader={tab} desktopHeader={desk} />
      <p className="accreditation">Auditorium Photograph by Tristram Kenton</p>
      <h1 className="sr-only">About the theatre</h1>
      <MaxWidth $wooden>
        <PageBody>
          <p>
            St. Martin's Theatre has been home to Agatha Christie’s The
            Mousetrap since March 1974. Located in the heart of London’s West
            End and theatre district, it has a capacity of 552 over three
            auditorium levels – Stalls (265), Dress Circle (123) and Upper
            Circle (153) – and four boxes (11). The theatre has three bars and
            is fully air-conditioned. For venue hire enquiries, visit the{' '}
            <Link to="/contact/">Contact Us</Link> page.
          </p>
          <h2 className="sub-header">History</h2>
          <p>
            The St. Martin’s Theatre was designed by W. G. R. Sprague as one of
            a pair of theatres, along with the Ambassadors Theatre, also in West
            Street. Richard Verney, 19th Baron Willoughby de Broke, together
            with B. A. (Bertie) Meyer, commissioned Sprague to design the
            theatre buildings. Although the Ambassadors opened in 1913,
            construction of the St. Martin’s was delayed by the outbreak of the
            First World War. The theatre is still owned by the present Lord
            Willoughby de Broke and his family.
          </p>
          <p>
            The first production at the St. Martin’s was the spectacular
            Edwardian musical comedy Houp La!, starring Gertie Millar, which
            opened on 23 November 1916. The producer was the impresario Charles
            B. Cochran, who took a 21-year lease on the new theatre.
          </p>
          <p>
            Many famous British actors have passed through the St. Martin's. In
            April 1923 Basil Rathbone played Harry Domain in R.U.R. and in June
            1927 Henry Daniell appeared there as Gregory Brown in Meet the Wife.
            Successes at the theatre included Hugh Williams's play (later a
            film) The Grass is Greener, John Mortimer's The Wrong Side of the
            Park, and in 1970, the thriller Sleuth.
          </p>
          <p>
            After Cochran, Bertie Meyer ran the theatre intermittently until
            1967, when his son R. A. (Ricky) Meyer became administrator for the
            next two decades. The St. Martin's was Grade II listed by English
            Heritage in March 1973.
          </p>
        </PageBody>
      </MaxWidth>
    </Layout>
  )
}

export default AboutTheatre

export const AboutTheatreQuery = graphql`
  query {
    mobHeaderImg: file(name: { eq: "About-the-theatre-2_575x570" }) {
      childImageSharp {
        gatsbyImageData(
          width: 575
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    tabletHeaderImg: file(name: { eq: "About-the-theatre 2_991x570" }) {
      childImageSharp {
        gatsbyImageData(
          width: 991
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    deskHeaderImg: file(name: { eq: "about2-deslktop" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`
